import { cloneReactElement, forwardRef, refFn, setRef, useLazyRef } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useCallback, useMemo, useRef } from "react";
import { useMousePosition } from "./Mouse";

export interface IPopupAnchorProps {
    children: React.ReactNode | ((anchor: IPopupAnchorContext) => React.ReactNode);
}

export interface IPopupAnchorContext {
    getRect(): DOMRectReadOnly;
}

export let AnchorContext = React.createContext(undefined! as IPopupAnchorContext);

export const PopupAnchor = React.memo(forwardRef(function PopupAnchor(props: IPopupAnchorProps, ref: React.Ref<Element>) {
    let firstChildRef = useRef<Element>(null);
    let context = getAnchorContext(firstChildRef);

    let children = typeof props.children == 'function'
        ? (props.children as Function)(context)
        : props.children;
    let [first, ...rest] = React.Children.toArray(children);
    let firstWithRef = cloneReactElement(first as React.ReactElement, {
        ref: (val: Element) => {
            if (val) {
                setRef(firstChildRef, val);
                setRef(ref, val);
            }
        }
    });

    return <AnchorContext.Provider value={context}>
        {[firstWithRef, ...rest]}
    </AnchorContext.Provider>;
}));

export function usePopupAnchor<TElement extends Element>() {
    let ref = useRef<TElement>(null);
    let anchorContextRef = useLazyRef(() => getAnchorContext(ref));
    return [refFn(ref), anchorContextRef.current] as const;
}

export function getAnchorContext(ref: React.RefObject<Element | null>): IPopupAnchorContext {
    return {
        getRect: () => ref.current?.getBoundingClientRect()
            ?? { left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0 } as DOMRectReadOnly
    };
}

export function useMouseAnchor(moveBelowCursor = false): IPopupAnchorContext {
    let offsetToMoveBelowCursor = moveBelowCursor ? 18 : 0;
    let mouse = useMousePosition();
    return usePointAnchor(useCallback(() => ({
        x: mouse.current.x,
        y: mouse.current.y + offsetToMoveBelowCursor
    }), [mouse, offsetToMoveBelowCursor]));
}

export function usePointAnchor(getPoint: () => { x: number, y: number }): IPopupAnchorContext {
    return useMemo(() => ({
        getRect: () => {
            let point = getPoint();
            return {
                left: point.x,
                right: point.x,
                top: point.y,
                bottom: point.y,
                width: 0,
                height: 0
            } as DOMRectReadOnly;
        }
    }), [getPoint]);
}