import { HistoryModel } from "@zap/utils/lib/HistoryModel";
import { dataProps, IChildren } from "@zap/utils/lib/ReactHelpers";
import { Route, RouteValues } from "@zap/utils/lib/Routing";
import { continueSelection } from "@zap/utils/lib/MultiSelect";
import * as React from "react";
import { createContext, useContext } from "react";
import { StyleCollection } from "stylemap";
import { Styled } from "./styling";

interface ICommonLinkProps extends IChildren {
    replaceHistory?: boolean;
    onClick?(): void;
    styles?: StyleCollection;
}

export interface IRouteLinkProps<TRoute extends Route<any>> extends ICommonLinkProps {
    route: TRoute;
    args?: RouteValues<TRoute>;
}

export interface IExternalLinkProps extends ICommonLinkProps {
    externalUrl: string;
    target?: string;
    download?: string | boolean;
}

export function Link<TRoute extends Route<any>>({ replaceHistory, onClick, styles, children, ...props }: IRouteLinkProps<TRoute> | IExternalLinkProps) {
    let history = useContext(Navigation);

    let linkProps = 'route' in props
        ? { href: props.route(props.args) }
        : { href: props.externalUrl, target: props.target, download: props.download };

    return <Styled.a {...linkProps} styles={styles} onClick={onLinkClick} {...dataProps(props)}>
        <LinkContext.Provider value={true}>
            {children}
        </LinkContext.Provider>
    </Styled.a>

    function onLinkClick(e: React.MouseEvent) {
        if (e.button == 0 && !continueSelection(e) && history && !linkProps.download) {
            e.preventDefault();
            if (replaceHistory)
                history.replaceState(undefined, linkProps.href);
            else
                history.pushState(undefined, linkProps.href);
        }
        onClick?.();
    }
}

export const Navigation = createContext<HistoryModel | undefined>(undefined);
export const LinkContext = createContext(false);