import "@zap/utils/lib/ArrayExtensions";
import { RefFn } from "@zap/utils/lib/ReactHelpers";
import * as React from 'react';
import { ReactElement, useState } from "react";
import { DomRegistrar, IDomScope, useDomScope } from "./DomScope";

export interface IFocusHelperProps extends IFocusConfig {
    children(focusHelper: IFocusHelper, ref: RefFn<HTMLElement>): ReactElement;
}

export function FocusHelper(props: IFocusHelperProps) {
    let focus = useFocus(props);
    let childRef = focus.registrar.useChildRef();
    return <DomRegistrar.Provider value={focus.registrar}>
        {props.children(focus, childRef)}
    </DomRegistrar.Provider>
}

export function useFocus(props: IFocusConfig = {}): IFocusHelper & IDomScope {
    let [isFocused, setFocused] = useState(false);
    if (isFocused && props.disabled)
        setFocused(false);

    let domScope = useDomScope();

    return {
        ...domScope,
        isFocused,
        getFocusProps: <T extends object>(conflictingProps?: T) => {
            let { onFocus, onBlur, ...rest } = (conflictingProps ?? {}) as IFocusProps;
            return {
                onFocus(event: React.FocusEvent<HTMLElement>) {
                    onFocus?.(event);
                    if (!isFocused) {
                        setFocused(true);
                        props.onFocus?.(event);
                    }
                },
                onBlur(event: React.FocusEvent<HTMLElement>) {
                    onBlur?.(event);
                    let focusedElement = (event.relatedTarget as Element) ?? document.activeElement; // relatedTarget will be null when cypress simulates a blur event
                    if (!focusedElement || domScope.elements.none(el => el.contains(focusedElement))) {
                        setFocused(false);
                        props.onBlur?.(event);
                    }
                },
                ...rest
            } as IFocusProps & T;
        }
    };
}

export interface IFocusConfig {
    disabled?: boolean;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
}

export interface IFocusHelper {
    getFocusProps<T extends object>(conflictingProps?: T): IFocusProps & T;
    isFocused: boolean;
}

export interface IFocusProps {
    onFocus: React.FocusEventHandler<HTMLElement>;
    onBlur: React.FocusEventHandler<HTMLElement>;
}