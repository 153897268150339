import { useMaybeControlledState } from "@zap/utils/lib/ReactHelpers";
import { Uuid } from "@zap/utils/lib/Uuid";
import * as React from "react";
import { createContext, KeyboardEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RegisteredStyles } from "stylemap";
import { Column, Row } from "./Box";
import { ClickEvent } from "./Clickable";
import { DisabledContext } from "./Disabling";
import { formLabelHeight } from "./FormLabel";
import { style } from "./styling";

export interface IFormProps {
    children: React.ReactNode;
    onSubmit?(form: IFormHelper): void;
    onCancel?(form: IFormHelper): void;
    onReset?(form: IFormHelper): void;
    direction?: 'vertical' | 'horizontal';
    disabled?: boolean;
    submitted?: boolean;
}

export interface IFormHelper {
    submit(): void;
    cancel(): void;
    reset(): void;
    direction?: 'vertical' | 'horizontal';
    submitted: boolean;
    hasLabels: boolean;
    registerLabel(id: string, label?: ReactNode): void;
    removeLabel(id: string): void;
}

export interface IVerticalFormProps extends IFormProps {
    grow?: boolean;
    sidePadding?: boolean;
}

export function VerticalForm(props: IVerticalFormProps) {
    let { children, grow, sidePadding, ...formProps } = props;
    return <Form {...formProps} direction="vertical">
        <Column grow={grow} sidePadding={sidePadding} width={grow ? undefined : defaultFormWidth} alignItems="stretch">
            {children}
        </Column>
    </Form>;
}

export const defaultFormWidth = 400;

export interface IHorizontalFormProps extends IFormProps {
    width?: number;
    sidePadding?: boolean;
}

export function HorizontalForm(props: IHorizontalFormProps) {
    let { children, width, sidePadding, ...formProps } = props;
    return <Form {...formProps} direction="horizontal">
        <Row alignItems="flex-end" width={width} sidePadding={sidePadding}>
            {children}
        </Row>
    </Form>;
}

/** Groups a set of fields that are submitted/cancelled/cleared together. */
export function Form(props: IFormProps) {
    let [labels, setLabels] = useState<{ [id: string]: string | undefined }>({});
    let [revision, setRevision] = useState(0);
    let [submitted, setSubmitted] = useMaybeControlledState(false, props.submitted);
    let hasLabels = useMemo(() => Object.values(labels).some(l => !!l), [labels]);
    let formHelper = useMemo<IFormHelper>(
        () => ({ submit, cancel, reset, direction: props.direction, submitted, hasLabels, registerLabel, removeLabel }),
        [props.onSubmit, props.onCancel, props.onReset, props.direction, submitted, hasLabels]);

    return <FormContext.Provider key={'rev' + revision} value={formHelper}>
        <DisabledContext disabled={props.disabled}>
            {props.children}
        </DisabledContext>
    </FormContext.Provider>;

    function submit() {
        if (props.onSubmit)
            props.onSubmit(formHelper);
        setSubmitted(true);
    }

    function cancel() {
        if (props.onCancel)
            props.onCancel(formHelper);
        clearForm();
    }

    function reset() {
        if (props.onReset)
            props.onReset(formHelper);
        clearForm();
    }

    function clearForm() {
        setRevision(r => r + 1); // Resets form controls to default values
        setSubmitted(false);
    }

    function registerLabel(id: string, label?: string) {
        setLabels(existing => ({ ...existing, [id]: label }));
    }

    function removeLabel(id: string) {
        setLabels(existing => {
            delete existing[id];
            return existing;
        });
    }
}

let FormContext = createContext<IFormHelper>({
    submit() { },
    cancel() { },
    reset() { },
    submitted: false,
    registerLabel() { },
    removeLabel() { },
    hasLabels: true
});

/** Provides a keydown handler that submits form on enter and cancels form on escape. */
export function FormOnKeyDown(props: { children(onKeyPress: (event: KeyboardEvent<HTMLElement>) => void): ReactNode }) {
    let onKeyPress = useFormOnKeyDown();
    return <>{props.children(onKeyPress)}</>;
}

/** Provides a keydown handler that submits form on enter and cancels form on escape. */
export function useFormOnKeyDown() {
    let form = useFormContext();
    return (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key == 'Enter') {
            if (event.currentTarget.tagName !== "TEXTAREA" || event.ctrlKey)
                form.submit();
        } else if (event.key == 'Escape') {
            form.cancel();
        }
    };
}

/** Provides a click handler that calls the appropriate form action based on the type of button. */
export function useFormButtonClick(type: string | undefined, onClick?: (e: ClickEvent) => void) {
    let form = useFormContext();

    return useCallback((e: ClickEvent) => {
        if (type == 'submit')
            form.submit();
        else if (type == 'cancel')
            form.cancel();
        else if (type == 'reset')
            form.reset();

        if (onClick)
            onClick(e);
    }, [form, type, onClick]);
}

/** Registers an input label with the form and returns any spacing to be used on the input. */
export function HorizontalFormSpacing({ label, affectsHorizontalLayout, children }: { label?: ReactNode, affectsHorizontalLayout?: boolean, children(formSpacing: (false | RegisteredStyles)[]): React.ReactNode }) {
    let formSpacing = useFormLabelSpacing(label, affectsHorizontalLayout);

    return <>{children(formSpacing)}</>;
}

/** Registers an input label with the form and returns any spacing to be used on the input. */
export function useFormLabelSpacing(label?: ReactNode, affectsHorizontalLayout?: boolean) {
    let form = useFormContext();

    if (affectsHorizontalLayout) {
        useEffect(() => {
            let id = Uuid.create();
            form.registerLabel(id, label);

            return () => {
                form.removeLabel(id);
            }
        }, [label]);
    }

    return useMemo(() =>
        [
            form.direction === 'horizontal' && labelAlignment,
            (!affectsHorizontalLayout || !label) && form.direction === 'horizontal' && form.hasLabels && labelSpacing
        ],
        [form, label]);
}

let labelAlignment = style('horizontalForm-alignment', {
    alignSelf: 'flex-start'
});

let labelSpacing = style('horizontalForm-labelSpacing', {
    paddingTop: formLabelHeight // align with bottom of inputs in horizontal form; below label and above any helper text
});

export function useFormContext() {
    return useContext(FormContext);
}

export interface IFieldRef<T extends {}> {
    field: T;
}

type FieldType<T extends ComponentType<any>> = PropsType<T> extends IFormFieldProps<infer TField> ? TField : never;
type PropsType<T extends ComponentType<any>> = T extends ComponentType<infer P> ? P : never;
type ComponentType<T> = React.ComponentType<T> | React.Component<T>;

/** Provides a box for storing a form field value. */
export function useField<T extends ComponentType<any>>(componentType?: T): IFieldRef<FieldType<T>> {
    let ref = useRef<FieldType<T>>(null!);
    return {
        get field() { return ref.current ?? {} as FieldType<T>; },
        set field(value: FieldType<T>) { ref.current = value; }
    };
}

/** Converts a field to a ref function. Will just be a noop if field is undefined. */
export function fieldAsRef<T extends {}>(fieldRef?: IFieldRef<T>) {
    return (field: T) => {
        if (fieldRef)
            fieldRef.field = field;
    };
}

export interface IFormFieldProps<T extends {}> {
    field?: IFieldRef<T>
}
