import { extendPrototype } from "./ExtensionHelpers";

let functionExtensions = {
    negate
};
extendPrototype(Function, functionExtensions);

type FunctionExtensions = typeof functionExtensions;
declare global { interface Function extends FunctionExtensions { } }

// Implementations below:

export function negate<T extends (...args: any[]) => boolean>(this: T) {
    let func = this;
    return function (this: any, ...args: any[]) { return !func.apply(this, args); } as T;
}