import { forwardRef, validProps } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { memo } from "react";
import { Row } from "../Box";
import { largeHeadingFontSize, semiBoldFontWeight, smallHeadingFontSize, truncateText } from "../CommonStyles";
import { style, Styled } from "../styling";

interface IHeadingProps {
    text: React.ReactNode;
}

export const LargeHeading = memo(forwardRef(function LargeHeading(props: IHeadingProps, ref: React.Ref<any>) {
    return <Heading {...props} ref={ref} />;
}));

export const SmallHeading = memo(forwardRef(function SmallHeading(props: IHeadingProps, ref: React.Ref<any>) {
    return <Heading small {...props} ref={ref} />;
}));

const Heading = memo(forwardRef(function Heading(props: IHeadingProps & { small?: boolean }, ref: React.Ref<any>) {
    return <Row styles={[heading, props.small ? smallHeading : largeHeading]} shrink {...validProps(props)} ref={ref}>
        <Styled.span styles={truncateText}>{props.text}</Styled.span>
    </Row>;
}));

export let largeHeadingFont = style('largeHeading-font', {
    fontWeight: 'lighter',
    ...largeHeadingFontSize
});

export let smallHeadingFont = style('smallHeading-font', {
    fontWeight: semiBoldFontWeight,
    ...smallHeadingFontSize
});

let heading = style('heading', {
    minWidth: 0 // otherwise it won't shrink when larger than parent
});

let largeHeading = style('largeHeading', {
    ...largeHeadingFont
});

let smallHeading = style('smallHeading', {
    ...smallHeadingFont
});