import { Center, Column, Row } from "@zap/ui/lib/Box";
import { largeHeadingFontSize, roundedBorders, whiteColor } from "@zap/ui/lib/CommonStyles";
import { defaultFormWidth } from "@zap/ui/lib/Form";
import { Elevation, shadow } from "@zap/ui/lib/shadow";
import { style, Styled } from "@zap/ui/lib/styling";
import { forwardRef, IChildren } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Ref } from "react";
import "../../../../SharedSource/Web.Core/Scripts/extensions";

export interface ILoginContainerProps extends IChildren {
    headerBackgroundColor: string;
    headerImage: string;
}

export const LoginContainer = forwardRef(function LoginContainer({ children, headerBackgroundColor, headerImage }: ILoginContainerProps, ref: Ref<HTMLDivElement>) {
    return <Center fit>
        <Column noSpacing styles={containerStyles} ref={ref}>
            <Column alignItems="center" innerPadding styles={header} inlineStyles={{ background: headerBackgroundColor }}>
                <Styled.img src={headerImage} styles={logo} />
                <Row maxWidth="90%">{PageText.Login_Title.template([ZapVariables.AppInfo.ApplicationName])}</Row>
            </Column>
            {children}
        </Column>
    </Center>
});

let containerStyles = style('container', {
    ...roundedBorders,
    ...shadow(Elevation.Card),
    background: 'white',
    width: defaultFormWidth,
    $: {
        [`@media(max-width: ${defaultFormWidth}px)`]: {
            width: '100vw'
        }
    },
    margin: 'auto'
});

let header = style('header', {
    ...largeHeadingFontSize,
    fontWeight: 'lighter',
    textAlign: 'center',
    color: whiteColor
});

let logo = style('logo', {
    height: 48
});