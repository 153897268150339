import { dataProps, forwardRef, useMaybeControlledState } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Column, Row } from "./Box";
import { darkHighlightColor, defaultFont, disabledBackground, disabledForeground, duration, formControlBackground, formControlFocus, formControlHover, highlightColor, interactiveForegroundColor } from "./CommonStyles";
import { useDisabled } from "./Disabling";
import { fieldAsRef, IFormFieldProps, useFormLabelSpacing, useFormOnKeyDown } from "./Form";
import { Elevation, shadow } from "./shadow";
import { standardFormControlHeight } from "./Sizes";
import { style, Styled, transition } from "./styling";

export interface ISwitchProps extends IFormFieldProps<HTMLInputElement> {
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: React.ReactNode;
}

export const Switch = forwardRef(function Switch(props: ISwitchProps, ref: React.Ref<HTMLLabelElement>) {
    let fieldRef = fieldAsRef(props.field);
    let formKeyDown = useFormOnKeyDown();
    let [checked, setChecked] = useMaybeControlledState(props.defaultChecked ?? false, props.checked);
    let formSpacing = useFormLabelSpacing();
    let disabled = useDisabled(props.disabled);

    return <Styled.label ref={ref} role="switch" aria-checked={!!checked} {...dataProps(props)} onKeyDown={formKeyDown} styles={formSpacing}>
        <Row justifyContent="space-between" center height={standardFormControlHeight}>
            {props.label && <Styled.div styles={defaultFont}>{props.label}</Styled.div>}
            <Column positioned spacing="none">
                <Styled.input ref={fieldRef} styles={checkBoxStyle} type="checkbox" checked={checked} onChange={change} disabled={disabled} />
                <Styled.span styles={switchStyle} />
            </Column>
        </Row>
    </Styled.label>;

    function change(e: React.ChangeEvent<HTMLInputElement>) {
        setChecked(e.target.checked);
        if (props.onChange)
            props.onChange(e);
    }
});

let checkBoxStyle = style('switch-checkbox', {
    opacity: 0,
    position: 'absolute',
    ':focus': {
        outline: 'none'
    }
});

let switchStyle = style('switch', {
    display: 'inline-block',
    width: 36,
    height: 24,
    position: 'relative',
    '::before': {
        content: '""',
        position: 'absolute',
        left: 1,
        right: 1,
        top: 5,
        height: 14,
        borderRadius: 7,
        opacity: 0.5,
        background: interactiveForegroundColor,
        transition: transition('all', duration.small),
        $: {
            [`.${checkBoxStyle}:checked + &`]: {
                background: highlightColor
            },
            [`.${checkBoxStyle}:disabled + &`]: {
                background: disabledForeground
            }
        }
    },
    '::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 2,
        width: 20,
        background: formControlBackground,
        borderRadius: '50%',
        ...shadow(Elevation.Switch),
        height: 20,
        transition: transition(['transform', 'background'], duration.small),
        $: {
            [`.${checkBoxStyle}:hover + &`]: {
                background: formControlHover
            },
            [`.${checkBoxStyle}:focus + &`]: {
                background: formControlFocus
            },
            [`.${checkBoxStyle}:checked + &`]: {
                transform: { translateX: 16 },
                background: highlightColor
            },
            [`.${checkBoxStyle}:not(:disabled):hover:checked + &`]: {
                background: darkHighlightColor
            },
            [`.${checkBoxStyle}:focus:checked + &`]: {
                background: darkHighlightColor
            },
            [`.${checkBoxStyle}:disabled + &`]: {
                background: disabledBackground
            }
        }
    }
});
