export class Color {
    private constructor(
        private readonly h: number,
        private readonly s: number,
        private readonly l: number,
        private readonly a: number,
        private readonly type: 'hsla' | 'rgba' | 'hex' | 'transparent'
    ) { }

    /** Supports hex6, hex8, hsl, hsla, rgb, rgba, and "transparent" */
    static parse(colorString: string) {
        if (colorString.toLowerCase() == 'transparent')
            return new Color(0, 0, 0, 0, 'transparent');

        if (colorString[0] == '#')
            return Color.hex(colorString);

        let hsla = /hsla?\(\s*([\d\.]+)\s*,\s*([\d\.]+)%\s*,\s*([\d\.]+)%\s*(?:,\s*([\d\.]+)\s*)?\)/i.exec(colorString);
        if (hsla)
            return Color.hsla(parseFloat(hsla[1]), parseFloat(hsla[2]) / 100, parseFloat(hsla[3]) / 100, hsla[4] ? parseFloat(hsla[4]) : 1);

        let rgba = /rgba?\(\s*([\d\.]+)\s*,\s*([\d\.]+)\s*,\s*([\d\.]+)\s*(?:,\s*([\d\.]+)\s*)?\)/i.exec(colorString);
        if (rgba)
            return Color.rgba(parseFloat(rgba[1]), parseFloat(rgba[2]), parseFloat(rgba[3]), rgba[4] ? parseFloat(rgba[4]) : 1);

        return null;
    }

    /**
     * @param h 0-360
     * @param s 0-1
     * @param l 0-1
     */
    static hsl(h: number, s: number, l: number) {
        return new Color(h, s, l, 1, 'hsla');
    }

    /**
     * @param h 0-360
     * @param s 0-1
     * @param l 0-1
     * @param a 0-1
     */
    static hsla(h: number, s: number, l: number, a: number) {
        return new Color(h, s, l, a, 'hsla');
    }

    /**
     * @param red - 0-255
     * @param green - 0-255
     * @param blue - 0-255
     */
    static rgb(red: number, green: number, blue: number) {
        return Color.rgba(red, green, blue, 1);
    }

    /**
     * @param red - 0-255
     * @param green - 0-255
     * @param blue - 0-255
     * @param alpha - 0-1
     */
    static rgba(red: number, green: number, blue: number, alpha: number) {
        return new Color(...rgbaToHsla(red, green, blue, alpha), 'rgba');
    }

    /**
     * @param hex - #RGB, #RGBA, #RRGGBB, #RRGGBBAA
     */
    static hex(hex: string) {
        let rgba = hexToRgba(hex);
        return rgba
            ? new Color(...rgbaToHsla(...rgba), 'hex')
            : null;
    }

    brighter(k: number = 1) {
        return Color.hsla(this.h, this.s, this.l / Math.pow(.7, k), this.a);
    }

    darker(k: number = 1) {
        return Color.hsla(this.h, this.s, Math.pow(.7, k) * this.l, this.a);
    }

    fade(opacityMultiplier: number) {
        return this.alpha(this.a * opacityMultiplier);
    }

    /** @param alpha - 0-1 */
    alpha(alpha: number) {
        return Color.hsla(this.h, this.s, this.l, alpha);
    }

    toHsla(): HslaObject {
        return { h: this.h, s: this.s, l: this.l, a: this.a };
    }

    /** Will output the same format the Color was created with. If the Color was modified, hsl(a) format will be used. */
    toString() {
        return this.a == 0 ? 'transparent'
            : this.type == 'hex' ? this.toHexString()
                : this.type == 'rgba' ? this.toRgbaString()
                    : this.toHslaString();
    }

    toHexString() {
        let [r, g, b, a] = hslaToRgba(this.h, this.s, this.l, this.a);
        return `#${hex(r)}${hex(g)}${hex(b)}${a == 1 ? '' : hex(a * 255)}`;

        function hex(value: number) {
            return Math.round(value).toString(16).padStart(2, '0');
        }
    }

    toRgbaString() {
        let [r, g, b, a] = hslaToRgba(this.h, this.s, this.l, this.a);
        return a == 1
            ? `rgb(${fix(r)}, ${fix(g)}, ${fix(b)})`
            : `rgba(${fix(r)}, ${fix(g)}, ${fix(b)}, ${fix(a)})`;
    }

    toHslaString() {
        let [h, s, l, a] = [this.h, this.s, this.l, this.a];
        return this.a == 1
            ? `hsl(${fix(h)}, ${fix(s * 100)}%, ${fix(l * 100)}%)`
            : `hsla(${fix(h)}, ${fix(s * 100)}%, ${fix(l * 100)}%, ${fix(a)})`;
    }
}

export interface HslaObject { h: number; s: number; l: number; a: number; }

function fix(number: number) {
    return +number.toFixed(3);
}

function rgbaToHsla(red: number, green: number, blue: number, alpha: number) {
    // From https://github.com/mjackson/mjijackson.github.com/blob/master/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript.txt

    var r = red / 255;
    var g = green / 255;
    var b = blue / 255;

    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);

    var l = (max + min) / 2;
    if (max == min)
        return [0, 0, l, alpha] as const;

    var d = max - min;
    var s = l > 0.5
        ? d / (2 - max - min)
        : d / (max + min);

    var h = max === r ? (g - b) / d + (g < b ? 6 : 0)
        : max === g ? (b - r) / d + 2
            : (r - g) / d + 4;

    h /= 6;

    return [h * 360, s, l, alpha] as const;
}

function hslaToRgba(h: number, s: number, l: number, a: number) {
    if (s === 0)
        return [l * 255, l * 255, l * 255, a] as const;

    h /= 360;

    var q = l < 0.5
        ? l * (1 + s)
        : l + s - l * s;

    var p = 2 * l - q;

    var r = getComponent(1 / 3);
    var g = getComponent(0);
    var b = getComponent(-1 / 3);

    return [r * 255, g * 255, b * 255, a] as const;

    function getComponent(offset: number) {
        var t = h + offset;

        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }
}

function hexToRgba(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i;

    hex = hex.replace(shorthandRegex, function (m, r, g, b, a) {
        return r + r + g + g + b + b + (a || '') + (a || '');
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

    if (!result)
        return null;

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    var a = parseInt(result[4] || 'FF', 16) / 255;

    return [r, g, b, a] as const;
}
