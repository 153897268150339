export interface IContinueSelectionKeys { ctrlKey: boolean; metaKey?: boolean; }
export interface IRangeSelectionKeys { shiftKey: boolean }

export type ISelectionEvent = ISelectionKeys & { type: string; button: number; }
export type ISelectionKeys = IContinueSelectionKeys & IRangeSelectionKeys;

/**
 * Do selections based on the specific mouse event. Goal is to match windows explorer behavior.
 * @param select should return true if selections were changed, otherwise false
 * @returns true if selections were changed, otherwise false
 */
export function handleSelectionEvent(event: ISelectionEvent, isSelected: boolean, select: (selectionKeys: ISelectionKeys) => boolean) {
    return event.button == 0
        && (event.type == 'mousedown' && (!isSelected || selectionKeysAreBeingHeldDown(event))
            || event.type == 'click' && isSelected && !selectionKeysAreBeingHeldDown(event))
        && select(selectionKeys(event));
}

function selectionKeysAreBeingHeldDown(event: ISelectionKeys) {
    return continueSelection(event)
        || rangeSelection(event);
}

/** [Ctrl] key on Windows, [Meta] key on MacOs */
export function continueSelection(event: IContinueSelectionKeys) {
    return ((event.ctrlKey && !isMacOs()) || (!!event.metaKey && isMacOs()));
}

/** [Shift] key */
export function rangeSelection(event: IRangeSelectionKeys) {
    return event.shiftKey;
}

function isMacOs() {
    var os = window.navigator.platform.toString();
    return os.includes("Mac");
}

export const noSelectionKeys: ISelectionKeys = { ctrlKey: false, shiftKey: false, metaKey: false };

/** Pick selection key properties off an event object */
export function selectionKeys(event: ISelectionKeys): ISelectionKeys {
    let { ctrlKey, shiftKey, metaKey } = event;
    return { ctrlKey, shiftKey, metaKey };
}