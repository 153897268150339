import * as React from "react";
import { fontSizes, labelFont, labelFontColor } from "./CommonStyles";
import { defaultDescenderHeight, defaultLabelHeight, standardLabelStyle } from "./StandardLabel";
import { style, StyleCollection, Styled } from "./styling";

export interface IFormLabelProps {
    label?: React.ReactNode;
    styles?: StyleCollection;
    color?: string;
}

export function FormLabel({ label, styles, color, ...rest }: IFormLabelProps & React.HTMLAttributes<HTMLElement>) {
    color = color ?? formLabelColor;

    return label
        ? <Styled.div
            data-testid={testIds.text}
            styles={[common, outerLabel, styles]}
            inline={{ color }}
            {...rest}>
            {label}
        </Styled.div>
        : null;
}

let common = style('formLabel', {
    userSelect: 'none',
    pointerEvents: 'none',
});

export let formLabelHeight = defaultLabelHeight;
export let formLabelDescenderHeight = defaultDescenderHeight;
export let formLabelColor = labelFontColor.color as string;

let outerLabel = style('is-formLabel-outer', {
    ...labelFont,
    ...standardLabelStyle(fontSizes.label)
});

export const testIds = {
    text: "form_label_text"
}
