import { combineRefs, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { ReactElement, Ref, useEffect, useRef } from "react";
import { useIsMouseDownOnElement, useWindowMouseMove } from "./Mouse";
import { Side } from "./Side";
import { standardSpacing } from "./Sizes";
import { CSSProperties, defaultPx, style, StyleCollection, Styled } from "./styling";
import React = require("react");

export function useWindowResize(onResize: (e: UIEvent) => void) {
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });
}

interface ResizableProps {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    onResize?: (width: number, height: number) => void;
    onReset?: () => void;
    children: ReactElement;
    styles?: StyleCollection;
    inline?: CSSProperties;
}

const resizeTriggerThreshold = standardSpacing;

export const Resizable = forwardRef(function Resizable({ top, right, bottom, left, onResize, onReset, children, styles, inline }: ResizableProps, ref: Ref<HTMLDivElement>) {
    let divRef = useRef<HTMLDivElement>();

    return <Styled.div styles={[resizable, styles]} inline={inline} ref={combineRefs(divRef, ref)}>
        {top && <ResizeHandle side='top' onResize={resize} onReset={reset} />}
        {right && <ResizeHandle side='right' onResize={resize} onReset={reset} />}
        {bottom && <ResizeHandle side='bottom' onResize={resize} onReset={reset} />}
        {left && <ResizeHandle side='left' onResize={resize} onReset={reset} />}
        {children}
    </Styled.div>

    function resize(side: Side, clientX: number, clientY: number) {
        let size = Math.round(getNewSize(side, clientX, clientY));

        if (isTopOrBottom(side))
            divRef.current!.style.height = size === null ? '' : defaultPx(size);
        else
            divRef.current!.style.width = size === null ? '' : defaultPx(size);

        let updatedRect = divRef.current?.getBoundingClientRect()!;
        onResize?.(updatedRect.width, updatedRect.height);
    }

    function getNewSize(side: Side, clientX: number, clientY: number) {
        let rect = divRef.current?.getBoundingClientRect()!;

        if (side === 'top')
            return rect.height + rect.top - clientY;
        else if (side === 'right')
            return rect.width + clientX - rect.right;
        else if (side === 'bottom')
            return rect.height + clientY - rect.bottom;
        else
            return rect.width + rect.left - clientX;
    }

    function reset(side: Side) {
        if (isTopOrBottom(side))
            divRef.current!.style.height = '';
        else
            divRef.current!.style.width = '';

        onReset?.();
    }
}) as <T>(props: ResizableProps & { ref?: Ref<T> }) => ReactElement;

interface ResizeHandleProps {
    onResize: (side: Side, clientX: number, clientY: number) => void;
    onReset: (side: Side) => void;
    side: Side;
}

function ResizeHandle({ side, onResize, onReset }: ResizeHandleProps) {
    let [handleRef, isMouseDown] = useIsMouseDownOnElement<HTMLDivElement>();
    useWindowMouseMove(windowMouseMove);
    useWindowResize(() => onReset(side));

    let cursorStyle = isTopOrBottom(side)
        ? nsResize
        : ewResize;

    return <Styled.div styles={[resizeArea, cursorStyle]} inline={getPosition()} onDoubleClick={onDoubleClick} ref={handleRef} />

    function windowMouseMove(e: MouseEvent) {
        if (!isMouseDown) {
            document.body.classList.remove(ewResize.toString());
            document.body.classList.remove(nsResize.toString());
        } else {
            document.body.classList.add(cursorStyle.toString());
            resize(e.clientX, e.clientY);
        }
    }

    function getPosition() {
        if (side === 'top')
            return { width: '100%', height: resizeTriggerThreshold * 2, top: -resizeTriggerThreshold };
        else if (side === 'right')
            return { width: resizeTriggerThreshold * 2, height: '100%', right: -resizeTriggerThreshold, top: 0 };
        else if (side === 'bottom')
            return { width: '100%', height: resizeTriggerThreshold * 2, bottom: -resizeTriggerThreshold };
        else
            return { width: resizeTriggerThreshold * 2, height: '100%', left: -resizeTriggerThreshold, top: 0 }
    }

    function resize(clientX: number, clientY: number) {
        onResize(side, clientX, clientY);
    }

    function onDoubleClick() {
        onReset(side);
    }
}

function isTopOrBottom(side: Side) {
    return side === 'top' || side === 'bottom';
}

let nsResize = style('nsresize', {
    cursor: 'ns-resize',
    userSelect: 'none',
    $: {
        '*': {
            cursor: 'ns-resize',
            userSelect: 'none',
        }
    }
});

let ewResize = style('ewresize', {
    cursor: 'ew-resize',
    userSelect: 'none',
    $: {
        '*': {
            cursor: 'ew-resize',
            userSelect: 'none',
        }
    }
});

let resizable = style('resizable', {
    position: 'relative'
});

export let resizeArea = style('resize-area', { // exported for debugging purposes
    position: 'absolute'
});
