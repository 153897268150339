import { forwardRef, IChildren, validProps } from '@zap/utils/lib/ReactHelpers';
import * as React from 'react';
import { FocusEventHandler, FormEventHandler, KeyboardEventHandler, MouseEventHandler, Ref } from "react";
import { halfSpacing, standardSpacing, subGrid } from "./Sizes";
import { CSSProperties, style, StyleCollection, Styled, TLength } from './styling';

export interface IBoxStyleProps {
    flex?: string;
    grow?: boolean;
    shrink?: boolean;
    flexBasis?: CSSProperties['flexBasis'];
    order?: number;
    inline?: boolean;
    column?: boolean;
    reverse?: boolean;
    wrap?: true | CSSProperties['flexWrap'];
    alignContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'stretch';
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'stretch';
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
    alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
    fit?: boolean;
    center?: boolean;
    width?: TLength;
    height?: TLength;
    minWidth?: TLength;
    minHeight?: TLength;
    maxWidth?: TLength;
    maxHeight?: TLength;
    /** @deprecated use spacing instead */
    noSpacing?: boolean;
    /** @deprecated use spacing instead */
    halfSpacing?: boolean;
    spacing?: Spacing;
    /** Spacing between 'lines' when wrapping. Defaults to value of {@link spacing}. */
    wrapSpacing?: Spacing;
    /** @deprecated use topBottomPadding and/or sidePadding instead */
    innerPadding?: boolean;
    topBottomPadding?: boolean | 'half';
    sidePadding?: boolean;
    styles?: StyleCollection;
    inlineStyles?: CSSProperties;
    className?: string;
    hideOverflow?: boolean;
    scrollX?: boolean | 'scroll';
    scrollY?: boolean | 'scroll';
    positioned?: boolean;
}

export interface IBoxProps extends IBoxStyleProps, Partial<IChildren> {
    role?: string;
    tabIndex?: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDoubleClick?: MouseEventHandler<HTMLDivElement>;
    onInput?: FormEventHandler<HTMLDivElement>;
    onFocus?: FocusEventHandler<HTMLDivElement>;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    onMouseDown?: MouseEventHandler<HTMLDivElement>;
    onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
    onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
    onMouseEnter?: MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

export let Box = forwardRef(function Box(props: IBoxProps, ref: Ref<HTMLDivElement>) { return renderBox(props, ref); });
export let Row = forwardRef(function Row(props: IBoxProps, ref: Ref<HTMLDivElement>) { return renderBox({ ...props, column: false }, ref); });
export let Column = forwardRef(function Column(props: IBoxProps, ref: Ref<HTMLDivElement>) { return renderBox({ ...props, column: true }, ref); });
export let Center = forwardRef(function Center(props: IBoxProps, ref: Ref<HTMLDivElement>) { return renderBox({ ...props, center: true }, ref); });
export function Spacer() { return <Styled.div styles={spacer}></Styled.div>; }
export function Indent({ level = 1 }: { level?: number }) { return <Styled.div styles={noSpacing} inline={{ width: level * standardSpacing }}></Styled.div>; }

function renderBox(props: IBoxProps, ref: Ref<HTMLDivElement>) {
    let boxStyle = boxStyles(props);

    let { role, tabIndex, onClick, onDoubleClick, onInput, onFocus, onBlur, onMouseDown, onKeyPress, onKeyDown, onMouseEnter, onMouseLeave } = props;

    return <Styled.div ref={ref}
        styles={[boxStyle.styles, props.styles]}
        inline={{ ...boxStyle.inline, ...(props.inlineStyles ?? {}) }}
        className={props.className}
        {...{ role, tabIndex, onClick, onDoubleClick, onInput, onFocus, onBlur, onMouseDown, onKeyPress, onKeyDown, onMouseEnter, onMouseLeave }}
        {...validProps(props)}>
        {props.children}
    </Styled.div>;
}

function boxStyles(props: IBoxStyleProps): { styles: StyleCollection, inline: CSSProperties } {
    let spacing: Spacing = props.spacing
        ?? (props.noSpacing ? 'none'
            : props.halfSpacing ? 'half'
                : 'full');

    return {
        styles: [
            props.column ? column : row,
            props.inline && inline,
            props.reverse ? reverse : forward,
            spacingStyles[spacing],
            wrappingStyles[props.wrapSpacing ?? spacing],
            props.shrink && shrink,
            props.grow && grow,
            props.center && center,
            (props.hideOverflow || props.scrollX || props.scrollY) && hideOverflow,
            props.scrollX && scrollX,
            props.scrollY && scrollY,
            props.sidePadding && [sidePadding, fullBleed]
        ],
        inline: {
            flex: props.flex,
            flexBasis: props.flexBasis,
            order: props.order,
            flexWrap: props.wrap == true ? 'wrap' : props.wrap,
            alignContent: props.alignContent,
            justifyContent: props.justifyContent ?? (props.center ? 'center' : undefined),
            alignItems: props.alignItems,
            alignSelf: props.alignSelf,
            width: props.width
                ?? (props.fit ? '100%' : undefined),
            height: props.height
                ?? (props.fit ? '100%' : undefined),
            minWidth: props.minWidth,
            minHeight: props.minHeight,
            maxWidth: props.maxWidth,
            maxHeight: props.maxHeight,
            position: props.scrollX || props.scrollY || props.positioned ? 'relative' : undefined as CSSProperties['position'],
            padding: props.innerPadding
                ? spacing == 'half'
                    ? halfSpacing
                    : standardSpacing
                : props.topBottomPadding ? [props.topBottomPadding == 'half' ? halfSpacing : standardSpacing, 0]
                    : undefined,
            overflowX: typeof props.scrollX == 'string' ? props.scrollX : undefined,
            overflowY: typeof props.scrollY == 'string' ? props.scrollY : undefined
        }
    };
}

export let noSpacing = style('boxItem-noSpacing', {});
export let fullBleed = style('boxItem-fullBleed', {});
export let fullBleedLeft = style('boxItem-fullBleedLeft', {});
export let fullBleedRight = style('boxItem-fullBleedRight', {});
export let halfBleed = style('boxItem-halfBleed', {});
export let halfBleedLeft = style('boxItem-halfBleedLeft', {});
export let halfBleedRight = style('boxItem-halfBleedRight', {});

let fullSpacedItems = style('box-spacing', {});
let halfSpacedItems = style('box-halfSpacing', {});
let subGridSpacedItems = style('box-subGridSpacing', {});

let spacer = style('spacer', {
    flex: 1
});

let spacingStyles: Record<Spacing, StyleCollection> = {
    none: undefined,
    full: fullSpacedItems,
    half: halfSpacedItems,
    subGrid: subGridSpacedItems
};

let fullSpacedWrapping = style('box-wrap-spacing', {})
let halfSpacedWrapping = style('box-wrap-halfSpacing', {})
let subGridSpacedWrapping = style('box-wrap-subGridSpacing', {})

let wrappingStyles: Record<Spacing, StyleCollection> = {
    none: undefined,
    full: fullSpacedWrapping,
    half: halfSpacedWrapping,
    subGrid: subGridSpacedWrapping
};

export let row = style('row', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    $: {
        [`&.${fullSpacedItems}`]: { columnGap: standardSpacing },
        [`&.${halfSpacedItems}`]: { columnGap: halfSpacing },
        [`&.${subGridSpacedItems}`]: { columnGap: subGrid },

        [`&.${fullSpacedWrapping}`]: { rowGap: standardSpacing },
        [`&.${halfSpacedWrapping}`]: { rowGap: halfSpacing },
        [`&.${subGridSpacedWrapping}`]: { rowGap: subGrid }
    }
});

export let column = style('column', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    $: {
        [`&.${fullSpacedItems}`]: { rowGap: standardSpacing },
        [`&.${halfSpacedItems}`]: { rowGap: halfSpacing },
        [`&.${subGridSpacedItems}`]: { rowGap: subGrid },

        [`&.${fullSpacedWrapping}`]: { columnGap: standardSpacing },
        [`&.${halfSpacedWrapping}`]: { columnGap: halfSpacing },
        [`&.${subGridSpacedWrapping}`]: { columnGap: subGrid }
    }
});

let forward = style('box-forward', {
    $: {
        [`&.${row}`]: {
            $: {
                [`&.${fullSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginLeft: -standardSpacing }]] },
                [`&.${halfSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginLeft: -halfSpacing }]] },
                [`&.${subGridSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginLeft: -subGrid }]] },
            }
        },
        [`&.${column}`]: {
            $: {
                [`&.${fullSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginTop: -standardSpacing }]] },
                [`&.${halfSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginTop: -halfSpacing }]] },
                [`&.${subGridSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginTop: -subGrid }]] },
            }
        }
    }
});

let reverse = style('box-reverse', {
    $: {
        [`&.${row}`]: {
            flexDirection: 'row-reverse',
            $: {
                [`&.${fullSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginRight: -standardSpacing }]] },
                [`&.${halfSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginRight: -halfSpacing }]] },
                [`&.${subGridSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginRight: -subGrid }]] },
            }
        },
        [`&.${column}`]: {
            flexDirection: 'column-reverse',
            $: {
                [`&.${fullSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginBottom: -standardSpacing }]] },
                [`&.${halfSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginBottom: -halfSpacing }]] },
                [`&.${subGridSpacedItems}`]: { $: [[[`> * + .${noSpacing}`, `> .${noSpacing} + *`, `> * + .${spacer}`], { marginBottom: -subGrid }]] },
            }
        }
    }
});

let inline = style('box-inline', {
    display: 'inline-flex'
});

let shrink = style('box-shrink', {
    flexShrink: 1,
    $: {
        'body &': { // Just to override BI's .reactComponent * { flex-shrink: 0; }
            flexShrink: 1
        },
        [`.${row} &`]: {
            minWidth: 0
        },
        [`.${column} &`]: {
            minHeight: 0
        }
    }
});

let grow = style('box-grow', {
    flexGrow: 1,
    alignSelf: 'stretch'
});

let center = style('box-center', {
    justifyContent: 'center'
});

let hideOverflow = style('box-hideOverflow', {
    overflow: 'hidden'
});

let scrollX = style('box-scrollX', {
    overflowX: 'auto',
    $: {
        [`.${row} > &`]: {
            flexShrink: 1
        }
    }
});

let scrollY = style('box-scrollY', {
    overflowY: 'auto',
    $: {
        [`.${column} > &`]: {
            flexShrink: 1
        }
    }
});

export type Spacing = 'full' | 'half' | 'subGrid' | 'none';

/*
 Side padding
 ------------
 Goal is to keep padding on the side _look_ consistent, by allowing components
 whose content is indented to "bleed" towards the edges:

     ┌      Content alignment      ┐
 ┌───┊─────────────────────────────┊───┐
 │ ┌─┊─────────────────────────────┊─┐ │
 │ │ Default                       ┊ │ │
 │ └─┊─────────────────────────────┊─┘ │
 │┌──┊─────────────────────────────┊──┐│
 ││  Half-bleed (e.g. TextBox)     ┊  ││
 │└──┊─────────────────────────────┊──┘│
 ├───┊─────────────────────────────┊───┤
 │   Full-bleed (e.g. ribbon button)   │
 ├───┊─────────────────────────────┊───┤
 └───┊─────────────────────────────┊───┘
     └      Content alignment      ┘

 Legend:
 ␣ sidePadding class
 ↔ where the margin goes

 */
let sidePadded = '&'; // Just for readability
let sidePadding = style('box-sidePadding', {
    $: [
        [
            [
                /*
                ┌───────────────┄
                │ ␣Row          
                │ ┌──────┐┌─────┄
                │↔│ ␣Col ││ ␣Col
                │ └──────┘└─────┄
                └───────────────┄
                */
                `${sidePadded}.${row} > ${sidePadded}.${column}:first-child`,
                /*
                ┌────────┄
                │ !␣Row   
                │ ┌──────┄
                │↔│ ␣Col 
                │ └──────┄
                │ ┌──────┄
                │↔│ ␣Col   
                │ └──────┄
                └────────┄
                 */
                `:not(${sidePadded}) > ${sidePadded}.${column}`,
                `:not(.${row}) > ${sidePadded}.${column}`
            ],
            {
                $: [
                    ['> *', {
                        marginLeft: standardSpacing
                    }],
                    [[`> .${halfBleedLeft}`, `> .${halfBleed}`], {
                        marginLeft: halfSpacing
                    }],
                    [[`> .${fullBleedLeft}`, `> .${fullBleed}`], {
                        marginLeft: 0
                    }]
                ]
            }
        ],
        [
            [
                /*
                ┄────────────────┐
                 ␣Row            │
                ┄──────┐┌──────┐ │
                  ␣Col ││ ␣Col │↔│
                ┄──────┘└──────┘ │
                ┄────────────────┘
                */
                `${sidePadded}.${row} > ${sidePadded}.${column}:last-child`,
                /*
                ┄──────────┐
                 !␣Row     │
                ┄────────┐ │
                  ␣Col   │↔│
                ┄────────┘ │
                ┄────────┐ │
                  ␣Col   │↔│
                ┄────────┘ │
                ┄──────────┘
                 */
                `:not(${sidePadded}) > ${sidePadded}.${column}`,
                `:not(.${row}) > ${sidePadded}.${column}`
            ],
            {
                $: [
                    ['> *', {
                        marginRight: standardSpacing
                    }],
                    [[`& > .${halfBleedRight}`, `& > .${halfBleed}`], {
                        marginRight: halfSpacing
                    }],
                    [[`& > .${fullBleedRight}`, `& > .${fullBleed}`], {
                        marginRight: 0
                    }]
                ]
            }
        ],
        [
            [
                /*
                ┌────────────────────┄
                │ ␣Row                
                │┌───────────┐┌──────┄
                ││ ␣Row      ││ ␣Row 
                ││ ┌───┐┌───┐││┌───┐ 
                ││↔│ * ││ * ││││ * │ 
                ││ └───┘└───┘││└───┘ 
                │└───────────┘└──────┄
                └────────────────────┄
                */
                `${sidePadded}.${row} > ${sidePadded}.${row}:first-child > :first-child`,
                /*
                ┌─────────────────────────┄
                │ !␣Row               
                │┌───────────┐┌───────────┄
                ││ ␣Row      ││ ␣Row      
                ││ ┌───┐┌───┐││ ┌───┐┌───┐ 
                ││↔│ * ││ * │││↔│ * ││ * │ 
                ││ └───┘└───┘││ └───┘└───┘ 
                │└───────────┘└───────────┄
                └─────────────────────────┄
                */
                `:not(${sidePadded}) > ${sidePadded}.${row} > :first-child`,
                `:not(.${row}) > ${sidePadded}.${row} > :first-child`
            ],
            {
                marginLeft: standardSpacing,
                $: [
                    [
                        [
                            `&.${halfBleedLeft}`,
                            `&.${halfBleed}`
                        ],
                        {
                            marginLeft: halfSpacing
                        }
                    ],
                    [
                        [
                            `&.${fullBleedLeft}`,
                            `&.${fullBleed}`
                        ],
                        {
                            marginLeft: 0
                        }
                    ]
                ]
            }
        ],

        [
            [
                /*
                ┄────────────────────┐
                 ␣Row                │
                ┄──────┐┌───────────┐│
                  ␣Row ││ ␣Row      ││
                  ┌───┐││┌───┐┌───┐ ││
                  │ * ││││ * ││ * │↔││
                  └───┘││└───┘└───┘ ││
                ┄──────┘└───────────┘│
                ┄────────────────────┘
                */
                `${sidePadded}.${row} > ${sidePadded}.${row}:last-child > :last-child`,
                /*
                ┄─────────────────────────┐
                 !␣Row                    │
                ┄───────────┐┌───────────┐│
                  ␣Row      ││ ␣Row      ││
                 ┌───┐┌───┐ ││┌───┐┌───┐ ││
                 │ * ││ * │↔│││ * ││ * │↔││
                 └───┘└───┘ ││└───┘└───┘ ││
                ┄───────────┘└───────────┘│
                ┄─────────────────────────┘
                */
                `:not(${sidePadded}) > ${sidePadded}.${row} > :last-child`,
                `:not(.${row}) > ${sidePadded}.${row} > :last-child`
            ],
            {
                marginRight: standardSpacing,
                $: [
                    [
                        [
                            `&.${halfBleedRight}`,
                            `&.${halfBleed}`
                        ],
                        {
                            marginRight: halfSpacing
                        }
                    ],
                    [
                        [
                            `&.${fullBleedRight}`,
                            `&.${fullBleed}`
                        ],
                        {
                            marginRight: 0
                        }
                    ]
                ]
            }
        ]
    ]
});