import { IChildren } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { createContext, useContext } from "react";

const DisabledState = createContext(false);

export function DisabledContext({ disabled, children }: { disabled?: boolean } & IChildren) {
    disabled = useDisabled(disabled);
    return <DisabledState.Provider value={!!disabled}>{children}</DisabledState.Provider>
}

export function useDisabled(disabledLocally?: boolean) {
    return useContext(DisabledState) || !!disabledLocally;
}