export function scrollIntoView(element: Element, yOffset = 0, xOffset = 0, scrollBehavior: ScrollBehavior = 'smooth') {
    let scrollParent = findScrollParent(element);
    let { scrollTop, scrollLeft } = scrollParent;
    let viewRect = visibleRect(scrollParent, yOffset, xOffset);
    let elementRect = element.getBoundingClientRect();

    if (elementRect.top > viewRect.bottom) {
        scrollTop += elementRect.top - viewRect.bottom; // Scroll down to top of element
    } else if (elementRect.bottom < viewRect.top) {
        scrollTop -= viewRect.bottom - elementRect.top; // Scroll up to bottom of element
    } else if (elementRect.height <= viewRect.height) {
        if (elementRect.top < viewRect.top)
            scrollTop -= (viewRect.top - elementRect.top); // Scroll up to top of element
        else if (elementRect.bottom > viewRect.bottom)
            scrollTop += (elementRect.bottom - (viewRect.bottom)); // Scroll down to bottom of element
    }

    if (elementRect.left > viewRect.right) {
        scrollLeft += elementRect.left - viewRect.right; // Scroll right to left edge of element
    } else if (elementRect.right < viewRect.left) {
        scrollLeft -= viewRect.right - elementRect.left; // Scroll left to right edge of element
    } else if (elementRect.width <= viewRect.width) {
        if (elementRect.left < viewRect.left)
            scrollLeft -= (viewRect.left - elementRect.left); // Scroll left to left edge of element
        else if (elementRect.right > viewRect.right)
            scrollLeft += (elementRect.right - (viewRect.right)); // Scroll right to right edge of element
    }

    scrollParent.scrollTo({ top: scrollTop, left: scrollLeft, behavior: scrollBehavior });
}

/** Returns the client rect of an element *inside* its scrollbars */
function visibleRect(element: Element, yOffset = 0, xOffset = 0) {
    let boundingRect = element.getBoundingClientRect();
    let top = boundingRect.top + yOffset;
    let left = boundingRect.left + xOffset;
    let visibleWidth = element.clientWidth - 2 * xOffset;
    let visibleHeight = element.clientHeight - 2 * yOffset;

    return {
        top: top,
        left: left,
        bottom: top + visibleHeight,
        right: left + visibleWidth,
        width: visibleWidth,
        height: visibleHeight
    };
}

export function findScrollParent(target: Element) {
    return findParent(target, isScrollElement);
}

export function findOverflowContainer(target: Element) {
    return findParent(target, isOverflowContainer);
}

function findParent(target: Element, predicate: (elementStyle: CSSStyleDeclaration) => boolean) {
    let current = target.parentElement;
    while (current) {
        if (predicate(getComputedStyle(current!)))
            return current!;
        current = current!.parentElement;
    }

    return document.documentElement;
}

function isOverflowContainer(style: CSSStyleDeclaration) {
    return [style.overflow, style.overflowX, style.overflowY].some(o => o != 'visible');
}

function isScrollElement(style: CSSStyleDeclaration) {
    return ['auto', 'scroll'].includes(style.overflowX!)
        || ['auto', 'scroll'].includes(style.overflowY!)
        || ['auto', 'scroll'].includes(style.overflow!);
}

export function isPositioned(style: CSSStyleDeclaration) {
    return style.position != 'static';
}

export function relativeDocumentPosition(a: Node, b: Node) {
    let position = a.compareDocumentPosition(b);
    return position & (Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY) ? -1
        : position & (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS) ? 1
            : 0;
}
