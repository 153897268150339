import { IAsyncEvent, IObservable, reduce } from "event-reduce";
import { LoadState, ObservableLoadState } from "./LoadState";
import { IStreamEvent } from "./StreamEvent";

export function setFrom<T>(initial: T, setterEvent: IObservable<T>) {
    return reduce(initial).on(setterEvent, (_, value) => value).value;
}

export function setAndReset<T>(initial: T, setterEvent: IObservable<T>, resetEvent: IObservable<any>): T {
    return reduce(initial)
        .on(setterEvent, (_, value) => value)
        .on(resetEvent, () => initial)
        .value;
}

export interface ILoadStateMapOptions<Result, Context> {
    load: IAsyncEvent<Result, Context>;
    key: (context: Context) => string;
    stale?: (context: Context) => IObservable<any>;
}

export function loadStateMap<Result, Context>({ load, key, stale }: ILoadStateMapOptions<Result, Context>) {
    return reduce({} as { [key: string]: LoadState })
        .on(load.started, (all, { promise, context }) => ({ ...all, [key(context)]: new LoadState(load.filter(c => c == context), stale?.(context), promise) }))
        .value;
}

export function observableLoadStateMap<Item, Context>(event: IStreamEvent<Item, Context>, getKey: (context: Context) => string) {
    return reduce({} as { [key: string]: ObservableLoadState })
        .on(event.started, (all, { stream, context }) => ({ ...all, [getKey(context)]: new ObservableLoadState(event.filter(c => c == context), stream) }))
        .value;
}

export function resultMap<Result, Context>(event: IAsyncEvent<Result, Context>, getKey: (context: Context) => string, defaultValue?: Result) {
    return resultMapReduction(event, getKey, defaultValue).value;
}

export function resultMapWithReset<Result, Context>(event: IAsyncEvent<Result, Context>, reset: IObservable<any>, getKey: (context: Context) => string, defaultValue?: Result) {
    return resultMapReduction(event, getKey, defaultValue)
        .on(reset, () => ({}))
        .value;
}

export function resultMapReduction<Result, Context>(event: IAsyncEvent<Result, Context>, getKey: (context: Context) => string, defaultValue?: Result) {
    let reduction = reduce({} as Record<string, Result>);
    if (typeof defaultValue != 'undefined')
        reduction = reduction.on(event.started, (all, { context }) => ({ ...all, [getKey(context)]: defaultValue }));
    return reduction.on(event.resolved, (all, { result, context }) => ({ ...all, [getKey(context)]: result }));
}