import { defaultFont, highlightColor } from "./CommonStyles";
import { cssRules, darken, Rules } from "./styling";

export const globalStyles: Rules = {
    html: {
        height: '100%'
    },
    body: {
        ...defaultFont,
        margin: 0,
        height: '100%'
    },
    '*': {
        boxSizing: 'border-box',
        flexShrink: 0,
        '::before': { boxSizing: 'border-box' },
        '::after': { boxSizing: 'border-box' }
    },
    ':focus': {
        outlineColor: highlightColor,
    },
    '#react-app': {
        height: '100%'
    },
    a: {
        color: highlightColor,
        textDecoration: 'none',
        ':hover': {
            color: darken(highlightColor)
        }
    }
};

export function applyCommonGlobalStyles() {
    cssRules(globalStyles);
}
