import { extendPrototype } from "./ExtensionHelpers";

let mapExtensions = {
    toObject
};
extendPrototype(Map, mapExtensions);

type MapExtensions = typeof mapExtensions;
declare global { interface Map<K, V> extends MapExtensions { } }

// Implementations below:

export function toObject<K, V>(this: Map<K, V>, keySelector: (key: K) => string): { [key: string]: V };
export function toObject<V>(this: Map<string, V>): { [key: string]: V };
export function toObject<K, V>(this: Map<K, V>, keySelector: (key: K) => string = String): { [key: string]: V } {
    let obj = {} as { [key: string]: any };
    this.forEach((value, key) => obj[keySelector(key)] = value);
    return obj;
}