import { event, events, reduce, reduced, derived } from "event-reduce";

@events
export class EmbeddedLoginEvents {
    loginPopupOpened = event();
    loginPopupClosed = event();
    loginPopupFailedToOpen = event();
    loggedIn = event();
}

export class EmbeddedLoginModel {
    events = new EmbeddedLoginEvents();

    @derived
    get isLoading() {
        return this.loginState != LoginState.None;
    }

    @reduced
    loginState = reduce(LoginState.None, this.events)
        .on(e => e.loginPopupOpened, () => LoginState.LoggingIn)
        .on(e => e.loginPopupClosed, current => current == LoginState.LoggingIn ? LoginState.None : current)
        .on(e => e.loggedIn, () => LoginState.Redirecting)
        .value;

    @reduced
    error = reduce('', this.events)
        .on(e => e.loginPopupOpened, () => '')
        .on(e => e.loginPopupFailedToOpen, () => PageText.Login_PopupFailedToOpen)
        .value;
}

export enum LoginState {
    None,
    LoggingIn,
    Redirecting
}