import { forwardRef } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { IInputProps, useInput } from "./Input";

export type TextBoxType = 'tel' | 'url' | 'text' | 'email' | 'password';
export interface ITextBoxProps extends IInputProps<string> {
    type?: TextBoxType;
}

export const TextBox = forwardRef(function TextBox(props: ITextBoxProps, ref: React.Ref<HTMLInputElement>) {
    let { type = 'text', styles, ...inputProps } = props;
    return useInput({ type, styles, inputProps }).render({ inputRef: ref });
});
